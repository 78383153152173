import { FloorPlan, FloorPlanModel, Marker } from '../models';
import { FolderFull } from '../models/folder';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';
import _ from "lodash";

export default class FolderProvider {
    private endpointAdmin: string;
    private memoizedSort = _.memoize((arr: any[], sortAttribute: string) => arr.sort((a, b) => a[sortAttribute].localeCompare(b[sortAttribute])));

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}folder`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchFloorplans = (folderId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${folderId}/floorplans`).then(response => {
            const { floorplans } = response.data;
            const sortedFloorplans = this.memoizedSort([...floorplans], "name");
            return sortedFloorplans.map((floorPlan: FloorPlanModel) => {
                return FloorPlan.create(floorPlan);
            });
        });
    }

    getFolderById = (folderId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${folderId}`).then(response => {
            const folder = response.data as FolderFull;
            const statusCode = response.status;

            return { status: statusCode, folder };
        });
    }

    fetchFolderMarkers = (folderId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${folderId}/markers`).then(response => {
            const markers = response.data as Marker[];
            const statusCode = response.status;
            const sortedMarkers = this.memoizedSort([...markers], "name");

            return { status: statusCode, markers: sortedMarkers };
        });
    }

    createFolder = (folder: FolderFull) => {
        const newFolder = {...folder} as any;
        delete newFolder['_rev'];
        delete newFolder['position'];
        delete newFolder['_id'];
        delete newFolder['folder_id'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newFolder), headers).then(response => {
            const folder = response.data as FolderFull;
            const statusCode = response.status;

            return { status: statusCode, folder };
        });
    }

    updateFolder = (folderId: string, folder: FolderFull) => {
        delete folder['_rev'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${folderId}`, JSON.stringify(folder), headers).then(response => {
            const folder = response.data as FolderFull;
            const statusCode = response.status;

            return { status: statusCode, folder };
        });
    }

    deleteFolder = (folderId: string) => {
        return this.init().deleteWithCredentials(`${this.endpointAdmin}/${folderId}`).then(response => {
            const statusCode = response.status;
            const { job } = response.data;
            
            return { status: statusCode, job };
        });
    }
}