import * as React from 'react';

import {
    Typography
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import {
    inject,
    observer
} from 'mobx-react';
import { AccountTeamViewer } from '../../components/account-team-viewer';
import { AnalyticsTabList } from '../../components/analytics-tab-list';
import {
    AnalyticsTabForm,
    AnalyticsTabModal
} from '../../components/analytics-tab-modal';
import {
    EmailSearch,
    EmailSearchResults
} from '../../components/email-search';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    DialogResult,
    SimpleDialog
} from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import {
    Account,
    AnalyticsTab,
    ObjectAnalytics,
    Team,
    User,
    UserAnalyticsTab
} from '../../shared/domain';
import { RootStore } from '../../stores/root.store';
import AccountProvider from '../../providers/account.provider';
import TeamProvider from '../../providers/team.provider';
import UserProvider from '../../providers/user.provider';
import AnalyticsProvider from '../../providers/analytics.provider';

const SIMPLE_LIST_MAX_HEIGHT = 210;
const ACCOUNTS_PAGING_LIMIT = 20;
const TEAMS_PAGING_LIMIT = 10;
const ROWS_PER_PAGE = 10;

const TAB_ADDED_SUCCESSFULLY = `Tab added successfully`;
const TAB_EDITED_SUCCESSFULLY = `Tab edited successfully`;
const TAB_DELETED_SUCCESSFULLY = `Tab deleted successfully`;
const CREATE_NEW_TAB = 'Create a New Tab';

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        formContainer: {
            width: '35% !important'
        },
        accountsContainer: {
            flex: '0.65 !important',
        },
        userDataContainer: {
            flex: '0.35 !important'
        },
        divider: {
            margin: '1em 0 !important'
        },
    },
    container: {
        marginTop: '1.5em',
        display: 'flex',
        flex: '1',
        flexDirection: 'row'
    },
    divider: {
        margin: '2em 0' 
    },
    accountsContainer: {
        flex: '0.5',
    },
    userDataContainer: {
        flex: '0.5'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1,
        height: '100%'
    },
    searchContainer: {
        height: SIMPLE_LIST_MAX_HEIGHT
    },
    searchResultsContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    searchResultSimpleList: {
        height: '20em',
        whiteSpace: 'break-spaces'
    },
    analyticsTabList: {
        height: '20em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
};

interface States {
    currentEmail: string;
    currentTeam: Team | null;
    currentTeamOffset: number;
    searchCurrentTeam: Team | null;
    currentAccount: Account | null;
    currentAccountOffset: number;
    mainSpinnerText: string;
    dataPageIsLoading: boolean;
    searchIsLoading: boolean;
    modalOpen: boolean;
    modalText: string;
    addedRemovedModalText: string;
    isAddedRemovedDialogOpen: boolean;
    isRemove: boolean;
    networkError: boolean;
    accounts: Account[];
    isAccountsLoading: boolean;
    totalAccounts: number;
    searchResultTeams: Team[];
    searchResultAccounts: Account[];
    teams: Team[] | null;
    totalTeams: number;
    isTeamsLoading: boolean;
    users: User[];
    analyticsTabs: AnalyticsTab[];
    analyticsTabModalOpen: boolean;
    analyticsTabModalHeader: string;
    analyticsTabSubmitButtonText: string;
    analyticsTabAccount: Account | undefined;
    currentAnalyticsTab: AnalyticsTab | null;
    defaultAnalyticsTabForm: AnalyticsTabForm;
    isAnalyticsTabCreate: boolean | null;
    isAnalyticsTabLoading: boolean;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

const emptyAnalyticsTabForm: AnalyticsTabForm =  {
    tabName: '',
    url: '',
    userName: '',
    refreshRate: 60*5, // 5 minutes
    tabOrder: 0,
    accountId: ''
};

@inject('rootStore')
@observer
class AddRemoveTeam extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };
    
    state: States = {
        currentEmail: '',
        currentAccount: null,
        currentAccountOffset: 0,
        mainSpinnerText: 'Please wait. Loading data.',
        currentTeam: null,
        currentTeamOffset: 0,
        searchCurrentTeam: null,
        dataPageIsLoading: true,
        searchIsLoading: false,
        modalOpen: false,
        modalText: '',
        addedRemovedModalText: '',
        isAddedRemovedDialogOpen: false,
        isRemove: false,
        isAccountsLoading: false,
        networkError: false,
        accounts: [],
        totalAccounts: 0,
        searchResultTeams: [],
        searchResultAccounts: [],
        teams: null,
        totalTeams: 0,
        isTeamsLoading: false,
        users: [] as User[],
        analyticsTabAccount: undefined as Account | undefined,
        analyticsTabs: [] as AnalyticsTab[],
        analyticsTabModalOpen: false,
        analyticsTabSubmitButtonText: '',
        analyticsTabModalHeader: CREATE_NEW_TAB,
        currentAnalyticsTab: null,
        defaultAnalyticsTabForm: emptyAnalyticsTabForm,
        isAnalyticsTabCreate: null,
        isAnalyticsTabLoading: false,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    };
    
    accountProvider = new AccountProvider();
    teamProvider = new TeamProvider();
    userProvider = new UserProvider();
    analyticsProvider = new AnalyticsProvider();
    
    componentDidMount() {
        // const { onAuthError } = this.props;
        // if (onAuthError) {
        //     onAuthError();
        // }
        this.fetchAccounts(this.state.currentAccountOffset);
    };
    
    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    addRemoveTeam = (userId: string, teamId: string, isTeamRemove: boolean = false) => {
        if (!userId || !teamId) {
            return;
        }
        // Show the spinner
        this.setState({ dataPageIsLoading: true });

        const callbackPromise: Promise<User> =
            isTeamRemove ?
                this.userProvider.removeUserFromTeam(userId, teamId)
              : this.userProvider.addUserToTeam(userId, teamId);

        callbackPromise.then((user: User) => {
            this.UpdateUsers([user])
                .then((updatedSuccessful: boolean) => {
                    const { isRemove } = this.state;
                    // If an error occurred generates a generic network error message
                    if (!updatedSuccessful) {
                        this.setState({
                            dataPageIsLoading: false,
                            networkError: true
                        });
                        return;
                    }
                    const addedRemovedModalText = `Team ${isRemove ? 'deleted' : 'added'} successfully`
                    // Open the successful dialog if everything went well
                    this.setState({
                        isAddedRemovedDialogOpen: true,
                        addedRemovedModalText,
                        dataPageIsLoading: false // Stop the spinner
                    });
                }).catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        dataPageIsLoading: false,
                        networkError: true
                    });
                });
        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                dataPageIsLoading: false,
                networkError: true
            });
        });
    }
    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account,
            currentTeamOffset: 0
        });
        this.fetchTeamsFromAccount(account, 0);
    };

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    fetchTeamsFromAccount = (account: Account, offset:number, limit: number = TEAMS_PAGING_LIMIT) => {
        this.setState({isTeamsLoading: true});

        return this.accountProvider.fetchTeamsFromAccountId(account.accountId, offset, limit)
          .then((results) => {
            const { teams, totalCount } = results;
            if (!teams) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                currentTeamOffset: offset,
                currentAccount: account,
                isTeamsLoading: false,
                teams,
                totalTeams: totalCount
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    };
    
    onTeamDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentAccount,
                currentTeamOffset,
                totalTeams
            } = this.state;
            
            if (!currentAccount) {
                return;
            
            }
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTeams / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TEAMS_PAGING_LIMIT * Math.floor(totalTeams / TEAMS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentTeamOffset+TEAMS_PAGING_LIMIT :
                                currentTeamOffset-TEAMS_PAGING_LIMIT;
            this.setState({isTeamsLoading: true});
            this.fetchTeamsFromAccount(currentAccount, newOffset)
               .then(()=> resolve())
               .catch(() => reject());
        });
    };

    onAddTeamClicked = (team: Team) => {
        const { currentAccount } = this.state;
        const currentAccountName = currentAccount ? currentAccount.name : '';
        const modalText = `Are you sure you want to add the team "${team ? team.name : ''}" from account "${currentAccountName}" to the current user?`;
        this.setState({
            modalOpen: true,
            modalText,
            currentTeam: team,
            isRemove: false
        });
    };

    onConfirmationDialogClicked = (result: DialogResult) => {
        // Closes the dialog
        this.setState({modalOpen: false});

        if (result !== DialogResult.Ok) {
            return;
        }
        const {
            users: usersArray,
            currentTeam,
            currentAnalyticsTab,
            isRemove
        } = this.state;

        if (isRemove) {
            // If currentAnalyticsTab is defined it means that
            // we want to delete only one tab and not the user's team
            if (currentAnalyticsTab) {
                this.deleteAnalyticsTabs([currentAnalyticsTab])
                    .then(() => {
                        this.setState({
                            dataPageIsLoading: false,
                            isAddedRemovedDialogOpen: true,
                            isRemove: false,
                            currentAnalyticsTab: null,
                            addedRemovedModalText: TAB_DELETED_SUCCESSFULLY
                        });
                    }).catch((errorObject) => {
                        const {
                            rootStore: { adminStore }
                        } = this.props;
                        this.setState({
                            dataPageIsLoading: false,
                            currentAnalyticsTab: null,
                        });
                        adminStore.loadingError = `${errorObject.error} - ${errorObject.errorMessage}`
                    });
                return;
            }
        }

        if (!usersArray || !currentTeam) {
            return;
        }

        const { userId } = usersArray[0];
        const { team_id } = currentTeam;
        this.addRemoveTeam(userId ? userId : '', team_id, isRemove);

    };

    onAddedRemovedTeamDialogClicked = (result: DialogResult) => {
        this.setState({isAddedRemovedDialogOpen: false});
    };

    getUserAccounts = (accountIds: string[]): Promise<Account[]> => {
        return new Promise ((resolve, reject) => {
            const requests = [] as Array<Promise<Account>>;
            const accountFetched: string [] = [];
            accountIds.forEach(accountId => {
                if (accountFetched.indexOf(accountId) < 0) {
                    accountFetched.push(accountId);
                    requests.push(
                        this.accountProvider.fetchAccount(accountId)
                    );
                }
            });
            Promise.all(requests)
                   .then((userAccounts: Account[]) => {
                        return resolve(userAccounts);
                    }).catch(()=>{
                        reject();
                    });
        });
    }

    sortAnalyticsTabs = (analyticsTabs: AnalyticsTab[]) => {
        if (!analyticsTabs) {
            return [];
        }

        analyticsTabs.sort((a: AnalyticsTab, b: AnalyticsTab): number => {
            const nameA = a.name.trim().toLocaleLowerCase();
            const nameB = b.name.trim().toLocaleLowerCase();
            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

        return analyticsTabs;
    }

    UpdateUsers = (users: User[]) : Promise<boolean> => {
        return new Promise ((resolve, reject) => {
            // Fetch the user teams only if the user record
            // is valid (returned one user)
            if (users.length === 1) {
                const {
                    teams,
                    email
                } = users[0];

                const isEmailInUserPresent = !!email;

                // Check if the email is present in the user record
                if (!isEmailInUserPresent) {
                    // If it is not we add a fake user so
                    // EmailSearResults component will show an error message.
                    users.push(new User(0, true, [], true, 0));
                }

                if (isEmailInUserPresent && teams && (teams.length > 0)){
                    const requests = [];
                    for (const team of teams) {
                        requests.push(this.teamProvider.fetchTeamFromTeamId(team.teamId))
                    }

                    Promise.all(requests).then((searchResultTeams: Team[]) => {
                        const userAccountIds: string[] = [];
                        for (const team of searchResultTeams) {
                            const accounts = team?.accounts;
                            if (accounts) {
                                for (const account of accounts) {
                                    if (userAccountIds.indexOf(account) < 0) {
                                        userAccountIds.push(account);
                                    }
                                }
                            }
                        }
                        this.getUserAccounts(userAccountIds)
                            .then((searchResultAccounts)=> {
                                this.setState({
                                    currentEmail: email ? email : '',
                                    users,
                                    searchResultTeams,
                                    searchResultAccounts
                                });
                                resolve(true);
                            }).catch((error) => {
                                const { status } = error.response;
                                if (status === 401) {
                                    const { onAuthError } = this.props;
                                    if (!onAuthError) {
                                        return;
                                    }
                                    onAuthError();
                                    return;
                                }
                                this.setState({networkError: true});
                                reject(error);
                            });
                    }).catch(error => {
                        const { status } = error.response;
                        if (status === 401) {
                            const { onAuthError } = this.props;
                            if (!onAuthError) {
                                return;
                            }
                            onAuthError();
                            return;
                        }
                        this.setState({networkError: true});
                        reject(error);
                    });
                } else { // this account has no teams
                    this.setState({
                        currentEmail: email ? email : '',
                        users,
                        searchResultTeams: [],
                        searchResultAccounts: []
                    });

                    resolve(true);
                }
            } else {
                this.setState({
                    currentEmail: '',
                    users
                });

                resolve(false);
            }
        });
    };

    loadUserDetails = (email: string): Promise<User> => {
        const {
            rootStore: { adminStore }
        } = this.props;
        return new Promise ((resolve, reject) => {
            this.setState({
                searchIsLoading: true,
                analyticsTabs: [] as AnalyticsTab[]
            });
            this.userProvider.fetchUser(email.toLocaleLowerCase())
                .then((users: User[]) => {
                    this.UpdateUsers(users).then(() => {
                        if (!users || (users.length !== 1)) {
                            this.setState({ searchIsLoading: false });
                            return;
                        }

                        this.setState({
                            searchIsLoading: false
                        });

                        const user = users[0];
                        const { analytics } = user;
                        if (analytics) {
                            this.setState({isAnalyticsTabLoading: true}, () => {
                                const { tabs } = analytics;
                                const requests = [] as Array<Promise<AnalyticsTab>>;
                                if (tabs) {
                                    tabs.forEach((tab: UserAnalyticsTab) => {
                                        requests.push(
                                            this.analyticsProvider.getTab(tab.id)
                                        );
                                    });
                                    Promise
                                        .all(requests)
                                        .then((analyticsTabs: AnalyticsTab[]) => {
                                            this.setState({
                                                analyticsTabs: this.sortAnalyticsTabs(analyticsTabs),
                                                isAnalyticsTabLoading: false,
                                            })
                                        })
                                        .catch((errorObject) => {
                                            this.setState({
                                                isAnalyticsTabLoading: false
                                            });
                                            adminStore.loadingError = `${errorObject.error} - ${errorObject.errorMessage}`;
                                        });
                                } else {
                                    this.setState({
                                        isAnalyticsTabLoading: false
                                    });
                                }
                            });
                        }
                        return resolve(user);
                    });
                }).catch(error => {
                    this.setState({
                        searchIsLoading: false
                    });
                    adminStore.loadingError = `${error.error} - ${error.errorMessage}`;
                    return reject(error);
                });
        });
    }

    onSearchClicked = (email: string) => {
        this.loadUserDetails(email);
    };

    findTeamByTeamId = (teamId: string): Team | undefined => {
        if (!teamId) {
            return undefined;
        }
        const {
            searchResultTeams
        } = this.state;

        const currentTeam = searchResultTeams.find((team: Team) => {
            return team.team_id === teamId;
        });

        return currentTeam;
    }

    findAccountFromTeamId = (teamId: string) => {
        const team = this.findTeamByTeamId(teamId);
        if (!team) {
            return null;
        }
        const {
            accounts
        } = team;

        if (!accounts || (accounts.length < 1)) {
            return null;
        }

        const accountId = accounts[0];
        return {
            accountId,
            team
        }
    }

    getTabsFromTeamId = (teamId: string): Promise<{analyticsTabs: AnalyticsTab[], team?: Team, account?: Account}> => {
        return new Promise ((resolve, reject) => {
            const results = this.findAccountFromTeamId(teamId);

            if (!results) {
                return resolve({
                    analyticsTabs: [] as AnalyticsTab[]
                });
            }
            const {
                accountId,
                team
            } = results;
            const { accounts } = this.state;
            const currentAccount = accounts.find((account) => {
                return account.accountId === accountId;
            });
            if (!team || ! accountId) {
                return resolve({
                    analyticsTabs: [] as AnalyticsTab[]
                });
            }
    
            this.analyticsProvider
                .getTabs(accountId)
                .then((analyticsTabs) => {
                    resolve({
                        analyticsTabs,
                        account: currentAccount,
                        team
                    });
                })
                .catch((error: {error: string, errorMessage?:string}) => {
                    reject(error);
                });
        });
    }

    updateTabsList = (teamId: string) => {
        this.setState({
            isAnalyticsTabLoading: true
        });

        this.getTabsFromTeamId(teamId)
            .then((result: {analyticsTabs: AnalyticsTab[], team?: Team, account?: Account}) => {
                const {
                    account: analyticsTabAccount,
                    analyticsTabs,
                    team
                } = result;

                let newState = {
                    analyticsTabs: this.sortAnalyticsTabs(analyticsTabs),
                    isAnalyticsTabLoading: false,
                    analyticsTabAccount,
                }

                if (team){
                    newState = {...newState, ...{
                        searchCurrentTeam: team
                    }};
                }

                this.setState(newState);
            })
            .catch((error: {error: string, errorMessage?:string}) => {
                this.setState({
                    isAnalyticsTabLoading: false,
                });
                const {
                    rootStore: { adminStore }
                } = this.props;
                adminStore.loadingError = `${error.error}${error.errorMessage ? ` - ${error.errorMessage}`: ''}`
            });
    }

    onEmailSearchTeamClicked = (teamId: string) => {
        const {
            analyticsTabAccount,
            searchCurrentTeam
        } = this.state;
        if (!teamId || (!!searchCurrentTeam && searchCurrentTeam.team_id === teamId)) {
            return;
        }
        const analyticsTabAccountId = analyticsTabAccount?.accountId;
        if (analyticsTabAccountId) {
            const results = this.findAccountFromTeamId(teamId);
            if (results) {
                const {
                    accountId: currentTeamAccountId,
                    team
                } = results;

                if (currentTeamAccountId === analyticsTabAccountId) {
                    this.setState({ searchCurrentTeam: team});
                    return;
                }
            }
        }

        this.updateTabsList(teamId);
    }

    onDeleteTeam = (teamId: string) => {
        const {
            users,
            users: usersArray
        } = this.state;

        if (!usersArray || !teamId) {
            return;
        }

        const currentTeam = this.findTeamByTeamId(teamId);
        if (!currentTeam) {
            return;
        }

        const currentUserName = users ? users[0].userName : '';
        const modalText = `Are you sure you want to delete the team "${currentTeam ? currentTeam.name : ''}" from user "${currentUserName}"?`;
        this.setState({
            modalOpen: true,
            modalText,
            currentTeam,
            isRemove: true
        });
    }

    //#region Tabs events
    onAddTabClicked =() => {
        const { searchCurrentTeam } =this.state;
        const searchAccounts = searchCurrentTeam?.accounts;
        const defaultAccountId = searchAccounts ? searchAccounts[0] : '';
        const defaultAnalyticsTabForm = {...emptyAnalyticsTabForm, ...{accountId: defaultAccountId}};

        this.setState({
            analyticsTabModalHeader: CREATE_NEW_TAB,
            analyticsTabSubmitButtonText: 'Submit',
            analyticsTabModalOpen: true,
            defaultAnalyticsTabForm,
            isAnalyticsTabCreate: true
        });
    }

    onCloseAnalyticsTabModalClicked = () => {
        this.setState({
            analyticsTabModalOpen: false
        });
    }

    onSubmitTabFormClicked = (isAnalyticsTabCreate: boolean | null) => (analyticsTabForm: AnalyticsTabForm) => {
        // Check tab for edit or create
        if (isAnalyticsTabCreate === true) {
            this.createAnalyticsTab(analyticsTabForm)
        } else if (isAnalyticsTabCreate === false) {
            const { id } = analyticsTabForm;
            if (!!id) {
                this.editAnalyticsTab(analyticsTabForm); 
            } 
        }
       
    }

    triggerAnalyticsTabError = (errorObject?: {error:string, errorMessage: string}) => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const errorMessage =
            errorObject ?
                `${errorObject.error} - ${errorObject.errorMessage}`
            : 'Unable to perform the operation';
        adminStore.loadingError = errorMessage;

        this.setState({
            dataPageIsLoading: false,
            isAnalyticsTabCreate: null
        });
    }

    createAnalyticsTab = (analyticsTabForm: AnalyticsTabForm) => {
        const {
            tabName,
            url,
            userName,
            refreshRate,
            tabOrder,
            accountId
        } = analyticsTabForm;

        const {
            users,
            searchCurrentTeam
        } = this.state;
        const { userId } = users[0];

        if (!userId) {
            return;
        }

        this.setState({
            dataPageIsLoading: true
        });

        this.analyticsProvider
            .createNewTab(accountId, tabName, refreshRate, userName, url, userId)
            .then(result => {
                const tabId = result;

                const { analytics } = users[0];
                const currentUserTabs = analytics && analytics.tabs ? [...analytics.tabs] : [];
                currentUserTabs.push({ id: tabId, name: tabName, tab_order: tabOrder} as UserAnalyticsTab)

                this.userProvider
                    .updateUserTabs(userId, currentUserTabs)
                    .then(() => {
                        const { currentEmail } = this.state;
                        this.loadUserDetails(currentEmail)
                            .then(() => {
                                if (searchCurrentTeam) {
                                    this.updateTabsList(searchCurrentTeam.team_id);
                                }
                                this.setState({
                                    analyticsTabModalOpen: false,
                                    dataPageIsLoading: false,
                                    addedRemovedModalText: TAB_ADDED_SUCCESSFULLY,
                                    isAddedRemovedDialogOpen: true,
                                    isAnalyticsTabCreate: null
                                });
                            }).catch(error => {
                                this.triggerAnalyticsTabError(error);
                            });
                    }).catch(error => {
                        this.triggerAnalyticsTabError(error);
                    });
            }).catch(error => {
                this.triggerAnalyticsTabError(error);
            });
    }

    findTabFromAnalytics = (analytics: ObjectAnalytics | undefined, tabId: string): UserAnalyticsTab | undefined => {
        if (!analytics) {
            return;
        }
        const { tabs } = analytics;
        const tabFound = tabs.find((tab) => {
            return tab.id === tabId;
        });
        return tabFound;
    }

    onEditTabClicked = (analyticsTab: AnalyticsTab) => {
        const { users } = this.state;

        if (!analyticsTab || (users.length !== 1)) {
            return;
        }
        const {
            name,
            url,
            userName,
            refreshRate,
            accountId,
            id,
            userId
        } = analyticsTab;
        const { analytics } = users[0];
        const tab = this.findTabFromAnalytics(analytics, id);
        const tabOrder = tab ? tab.tab_order: 0;
        
        this.setState({
            analyticsTabModalHeader: 'Edit Tab',
            analyticsTabSubmitButtonText: 'Change',
            analyticsTabModalOpen: true,
            isAnalyticsTabCreate: false,
            defaultAnalyticsTabForm: {
                id,
                userId,
                tabName: name,
                url,
                userName: userName ? userName : '',
                tabOrder,
                refreshRate,
                accountId
            }
        });
    }

    editAnalyticsTab = (analyticsTabForm: AnalyticsTabForm) => {
        const {
            tabName,
            url,
            userName,
            refreshRate,
            tabOrder,
            accountId,
            id,
            userId
        } = analyticsTabForm;

        if (!id || !userId) {
            return;
        }

        this.setState({
            dataPageIsLoading: true
        });

        const {
            searchCurrentTeam,
            users
        } = this.state;

        const changeStateOnSuccess = () => {
            this.setState({
                analyticsTabModalOpen: false,
                dataPageIsLoading: false,
                addedRemovedModalText: TAB_EDITED_SUCCESSFULLY,
                isAddedRemovedDialogOpen: true,
                isAnalyticsTabCreate: null
            });
        }
        this.analyticsProvider
            .editTab(id, accountId, tabName, refreshRate, userName, url, userId)
            .then(result => {
                const tabId = result;

                const { analytics } = users[0];
                const currentUserTabs = analytics ? [...analytics.tabs] : [];

                // Search if an element with the same tab id already exists
                const oldTabIndex = currentUserTabs.findIndex((tab) => {
                    return tab.id === tabId;
                });
                // If the element exists...
                if (oldTabIndex >= 0) {
                    // ...It removes the existing element from the current tabs
                    // because it will be added again later on
                    currentUserTabs.splice(oldTabIndex, 1);
                }

                // It adds the element that was changed
                currentUserTabs.push({ id: tabId, name: tabName, tab_order: tabOrder } as UserAnalyticsTab)

                this.userProvider
                    .updateUserTabs(userId, currentUserTabs)
                    .then(userTabsResult => {
                        const { currentEmail } = this.state;
                        this.loadUserDetails(currentEmail).then(() => {
                            if (searchCurrentTeam) {
                                this.updateTabsList(searchCurrentTeam.team_id);
                            }
                            changeStateOnSuccess();
                        }).catch((error) => {
                            this.triggerAnalyticsTabError(error);
                        });
                    }).catch(error => {
                        this.triggerAnalyticsTabError(error);
                    });
            }).catch(error => {
                this.triggerAnalyticsTabError(error);
            });
    }

    onDeleteTabClicked = (analyticsTab: AnalyticsTab) => {
        if (!analyticsTab) {
            return;
        }

        const { users } = this.state;
        const currentUserName = users ? users[0].userName : '';
        const modalText = `Are you sure you want to delete the tab "${analyticsTab ? analyticsTab.name : ''}" from user "${currentUserName}"?`;
        this.setState({
            modalOpen: true,
            modalText,
            isRemove: true,
            currentAnalyticsTab: analyticsTab
        });
    }

    deleteAnalyticsTabs = (tabs: AnalyticsTab[]): Promise<void> => {
        return new Promise ((resolve, reject) => {
            const deleteTabsRequests = [] as Array<Promise<string>>;
            tabs.forEach(tab => {
                deleteTabsRequests.push(
                    this.analyticsProvider.deleteTab(tab.id)
                );
            });
            Promise
                .all(deleteTabsRequests)
                .then(() => {
                    const {
                        searchCurrentTeam,
                        users
                    } = this.state;
                    const { userId } = users[0];

                    if (!userId) {
                        return resolve();
                    }

                    const { analytics } = users[0];
                    const currentUserTabs = analytics ? analytics.tabs : [];
                    const userAnalyticsTabs = currentUserTabs.filter((analyticTab: UserAnalyticsTab) => {
                        const analyticTabId = analyticTab.id;
                        const index = tabs.findIndex(tab => {
                            return analyticTabId === tab.id;
                        })
                        return index < 0;
                    })
                    
                    this.userProvider
                        .updateUserTabs(userId, userAnalyticsTabs)
                        .then(() => {
                            const { currentEmail } = this.state;
                            this.loadUserDetails(currentEmail)
                                .then(() => {
                                    if (searchCurrentTeam) {
                                        this.updateTabsList(searchCurrentTeam.team_id);
                                    }
                                    resolve();
                                }).catch((error) => {
                                    return reject(error);
                                });
                        }).catch(error => {
                            return reject(error);
                        });
                }).catch(error => {
                    return reject(error);
                });
        });
    }
    //#endregion

    navigateToAccount = (teamId: string) => {
        const { searchResultAccounts, searchResultTeams, currentAccount } = this.state;

        const accountId = searchResultTeams.find(e => e.team_id === teamId)!.accounts[0];
        const account = searchResultAccounts.find(e => e.accountId === accountId);
        
        if (currentAccount === account || !account) {
            return;
        }

        this.setState({
            currentAccount: account,
            currentTeamOffset: 0
        });
        this.fetchTeamsFromAccount(account, 0);
    };

    public render() {
        const { classes } = this.props;
        const {
            currentAccount,
            currentEmail,
            searchCurrentTeam,
            mainSpinnerText,
            dataPageIsLoading,
            searchIsLoading,
            modalOpen,
            modalText,
            addedRemovedModalText,
            isAddedRemovedDialogOpen,
            networkError,
            accounts,
            currentAccountOffset,
            currentTeamOffset,
            totalAccounts,
            searchResultTeams,
            users,
            isTeamsLoading,
            isAccountsLoading,
            teams,
            totalTeams,
            analyticsTabs,
            analyticsTabModalOpen,
            analyticsTabModalHeader,
            analyticsTabSubmitButtonText,
            searchResultAccounts,
            // analyticsTabAccount,
            defaultAnalyticsTabForm,
            isAnalyticsTabCreate,
            isAnalyticsTabLoading
        } = this.state;
        
        const isSearchResultNotEmpty = !!users && (users.length > 0);
        const currentAccountName = currentAccount ? currentAccount.name : '';
        const searchCurrentTeamId = searchCurrentTeam?.team_id;
        const analytics = users && (users.length > 0) ? users[0].analytics : undefined;
        const totalAnalyticsTab = isSearchResultNotEmpty && analytics && (analytics.tabs) ? analytics.tabs.length : 0;
        const analyticsTabListHeaderText =
            // `User's Tabs ${analyticsTabAccount ? `[${analyticsTabAccount.name}] `: ''}(${analyticsTabs && (analyticsTabs.length > 0) ? `${analyticsTabs.length}/` : ''}${totalAnalyticsTab})`;
            `User's Tabs${totalAnalyticsTab > 0 ? ` (${totalAnalyticsTab})` : ''}`;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label ={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <div className={classes.accountsContainer}>
                        <AccountTeamViewer
                            rowsPerPage={ROWS_PER_PAGE}
                            accounts={accounts}
                            accountsIsLoading={isAccountsLoading}
                            accountsTotalItems={totalAccounts}
                            accountsItemsOffset={currentAccountOffset}
                            currentAccountName={currentAccountName}
                            teams={teams}
                            teamsTotalItems={totalTeams}
                            teamSelection={isSearchResultNotEmpty}
                            teamsIsLoading={isTeamsLoading}
                            teamsItemsOffset={currentTeamOffset}
                            onAccountClick={this.onAccountClicked}
                            onTeamClick={this.onAddTeamClicked}
                            onAccountDataPageOverBoundary={this.onAccountDataPageOverBoundaryReached}
                            onTeamDataPageOverBoundary={this.onTeamDataPageOverBoundaryReached}
                            onAccountSearch={this.searchAccounts}
                        />
                    </div>
                    <div className={classes.userDataContainer}>
                        <EmailSearch
                            email={currentEmail}
                            onSearchClick = {this.onSearchClicked}
                        />
                        <Divider className={classes.divider}/>
                        <CCSpinner
                            className={classes.searchContainer}
                            loading={searchIsLoading}
                            size={100}
                        >
                            <div className={classes.searchResultsContainer}>
                                <EmailSearchResults
                                    classes={{list: classes.searchResultSimpleList}}
                                    searchResultTerm={currentEmail}
                                    users={isSearchResultNotEmpty ? users : []}
                                    teams={searchResultTeams}
                                    currentTeamId={searchCurrentTeamId}
                                    onClick={this.navigateToAccount}
                                    needAccountLink={true}
                                    userAccounts={searchResultAccounts}
                                    onDelete={this.onDeleteTeam}
                                />
                                <AnalyticsTabList
                                    className={classes.analyticsTabList}
                                    headerText={analyticsTabListHeaderText}
                                    isLoading={isAnalyticsTabLoading}
                                    tabs={analyticsTabs}
                                    disabled={analyticsTabs.length !== totalAnalyticsTab}
                                    noItemsLabel={`${searchCurrentTeamId ? "No Tabs Available" : "Please Select a Team"}`}
                                    visible={isSearchResultNotEmpty}
                                    onAddTabClick={this.onAddTabClicked}
                                    onDelete={this.onDeleteTabClicked}
                                    onClick={this.onEditTabClicked}
                                />
                            </div>
                        </CCSpinner>
                    </div>
                    <SimpleDialog
                        open={isAddedRemovedDialogOpen}
                        titleText="Success"
                        contentText={addedRemovedModalText}
                        buttonCancelLabel=""
                        onDialogResult={this.onAddedRemovedTeamDialogClicked}
                    />
                    {
                        analyticsTabModalOpen ?
                            <AnalyticsTabModal
                                open={analyticsTabModalOpen}
                                accounts={searchResultAccounts}
                                headerText={analyticsTabModalHeader}
                                defaultAnalyticsTabForm={defaultAnalyticsTabForm}
                                submitButtonText={analyticsTabSubmitButtonText}
                                onSubmit={this.onSubmitTabFormClicked(isAnalyticsTabCreate)}
                                onClose={this.onCloseAnalyticsTabModalClicked}
                            />
                        :
                        ''
                    }
                </CCSpinner> 
                {
                    networkError ?
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header = ' '
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                    :
                        <SimpleDialog
                            open={modalOpen}
                            titleText="Please confirm"
                            contentText={modalText}
                            onDialogResult={this.onConfirmationDialogClicked}
                        />
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AddRemoveTeam);
export {MUIComponent as AddRemoveTeam}
